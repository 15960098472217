import React, { useState } from "react";
import { useSelector } from "react-redux";
import MainCard from "./Card/MainCard";
import { Pane, Spinner } from "evergreen-ui";
import { Autocomplete, TextField, Typography, styled } from "@mui/material";
import { master } from "..";

function RenderContent({ data, hasMorePosts }) {
  const lastIndex = data.length - 1;
  const isGuest = useSelector((state) => state.user.isGuest);

  return (
    <Pane paddingBottom={"5em"} paddingTop="0.5em">
      {data.map((content, index) => (
        <MainCard
          key={index}
          data={content}
          index={index}
          lastIndex={lastIndex}
          isGuest={isGuest}
        />
      ))}
      <Pane
        display="flex"
        justifyContent="center"
        color="var(--md-sys-color-text)"
      >
        {hasMorePosts ? <Spinner size={24} /> : "."}
      </Pane>
    </Pane>
  );
}

function SortContentControl() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { title: "Latest" },
    { title: "Trending" },
    { title: "Likes" },
    { title: "Controversial" },
    { title: "Shares" },
    { title: "Views" },
    { title: "Location" },
  ];

  const renderSelectedOption = (props, option, state) => {
    return selectedOptions.includes(option) ? null : (
      <StyledAutocompleteOption
        {...props}
        style={{
          backgroundColor: state.selected
            ? "var(--md-sys-color-secondary)"
            : "initial",
          border: "1px solid var(--md-sys-color-secondary)",
        }}
      >
        <Typography color={"var(--md-sys-color-on-primary)"}>
          {option.title}
        </Typography>
      </StyledAutocompleteOption>
    );
  };

  function handleSortChange(e, data) {
    master.sortPosts(data);
    document.activeElement.blur();
  }

  const handleInputFocus = (event) => {
    event.preventDefault(); // Prevent the default behavior (opening the keyboard)
  };

  const StyledAutocompleteOption = styled("div")({
    ":not(:last-child)": {
      borderBottom: "1px solid var(--md-sys-color-on-primary)",
    },
    margin: "center",
  });

  return (
    <Pane
      display="flex"
      marginBottom={4}
      marginTop={4}
      justifyContent="center"
      padding="8px"
      paddingLeft="16px"
      paddingRight="16px"
    >
      <Autocomplete
        size="small"
        multiple
        options={options}
        getOptionLabel={(option) => option.title}
        style={{
          width: 500,
          background: "var(--md-sys-color-secondary)",
          borderRadius: "4px",
        }}
        renderInput={(params) => (
          <TextField
            color="success"
            size="small"
            autoComplete={"true"}
            {...params}
            placeholder="Sort by..."
            InputProps={{
              ...params.InputProps,
              onFocus: handleInputFocus, // Prevent input element focus
            }}
          />
        )}
        renderOption={renderSelectedOption}
        componentsProps={{
          paper: {
            sx: {
              bgcolor: "var(--md-sys-color-surface)",
              color: "var(--md-sys-color-on-surface)",
            },
          },
        }}
        onChange={handleSortChange}
      />
    </Pane>
  );
}

function ContentDisplay({ mode }) {
  const posts = useSelector((state) => state.posts.posts);
  const hasMorePosts = useSelector((state) => state.posts.hasMorePosts);

  return (
    <>
      {/* <SortContentControl /> */}
      <RenderContent data={posts} hasMorePosts={hasMorePosts} />
    </>
  );
}

export default ContentDisplay;
