const categories = [
  {
    title: "Politics",
    icon: "",
  },
  {
    title: "Crime",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Romance",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Jobs/Vacancies",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Career",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Business",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Investment",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "NYSC",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Education",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Foreign Affairs",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Autos",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Car Talk",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Properties",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Health",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Travel",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Family",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Culture",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Religion",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Food",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Diaries",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Pets",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Agriculture",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Jokes",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "TV/Movies",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Music/Radio",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Celebrities",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Fashion",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Events",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Sports",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Gaming",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Forum Games",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Literature",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Programming",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Webmasters",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Computers",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Phones",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Art, Graphics & Video",
    icon: "", // Replace with SVG value for the icon
  },
  {
    title: "Technology Market",
    icon: "", // Replace with SVG value for the icon
  },
];

export default categories;
