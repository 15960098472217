import React, { useContext, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Theme, { ThemeContext } from "./Theme";
import UseAnimations from "react-useanimations";
import toggle from "react-useanimations/lib/toggle";
import Notification from "./components/Notification/Notification";
import Search from "./components/Search";
import Categories from "./components/Categories";
import { Divider } from "@mui/material";
import NavLinks from "./components/NavLinks";
import ContentDisplay from "./components/ContentDisplay";
import { Pane } from "evergreen-ui";

const toggleAnimation = toggle;

const css = {
  position: "absolute",
  top: 0,
  right: 0,
};

const appCSS = {
  background: ` repeating-linear-gradient( 45deg,#0000 calc(-650%/13) calc(50%/13),var(--md-sys-card-bg-image-color) 0 calc(100%/13),
#0000 0 calc(150%/13),var(--md-sys-card-bg-image-color) 0 calc(200%/13),
#0000 0 calc(250%/13),var(--md-sys-card-bg-image-color) 0 calc(300%/13)),repeating-linear-gradient( 45deg,#0000 calc(-650%/13) calc(50%/13),var(--md-sys-card-bg-image-color) 0 calc(100%/13),
#0000 0 calc(150%/13),var(--md-sys-card-bg-image-color) 0 calc(200%/13),
#0000 0 calc(250%/13),var(--md-sys-card-bg-image-color) 0 calc(300%/13)) 10px 10px,
repeating-linear-gradient(-45deg,#0000 calc(-650%/13) calc(50%/13),var(--md-sys-card-bg-image-color) 0 calc(100%/13),
#0000 0 calc(150%/13),var(--md-sys-card-bg-image-color) 0 calc(200%/13),
#0000 0 calc(250%/13),var(--md-sys-card-bg-image-color) 0 calc(300%/13)),repeating-linear-gradient(-45deg,#0000 calc(-650%/13) calc(50%/13),var(--md-sys-card-bg-image-color) 0 calc(100%/13),
#0000 0 calc(150%/13),var(--md-sys-card-bg-image-color) 0 calc(200%/13),
#0000 0 calc(250%/13),var(--md-sys-card-bg-image-color) 0 calc(300%/13)) 10px 10px var(--md-sys-color-bg)`,
  backgroundSize: "20px 20px",
  minHeight: "132vh",
};

function App() {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkModeOn, setDarkMode] = useState(prefersDark);

  return (
    <Theme prefersDark={prefersDark}>
      <Pane style={appCSS}>
        <DarkModeIcon />
        <Header />

        <Notification />
        <Search />
        {/* <Divider variant="middle" /> */}

        <ContentDisplay />

        <NavLinks />
      </Pane>
    </Theme>
  );

  function DarkModeIcon() {
    const { toggle } = useContext(ThemeContext);

    return (
      <div style={css}>
        <UseAnimations
          animation={toggleAnimation}
          size={32}
          onClick={changeThemeMode}
          autoplay={isDarkModeOn}
          strokeColor={"var(--md-sys-color-on-primary)"}
        />
      </div>
    );

    function changeThemeMode() {
      if (isDarkModeOn) {
        setDarkMode(false);
      } else {
        setDarkMode(true);
      }
      toggle();
    }
  }
}

export default App;
