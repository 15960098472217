import React, { useState, useLayoutEffect } from "react";
import "./CSS/theme.css";

export const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default function ThemeProvider({ children, prefersDark }) {
  const [dark, setDark] = useState(prefersDark);

  useLayoutEffect(() => {
    applyTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dark]);

  const applyTheme = () => {
    let theme;
    if (dark) {
      theme = darkTheme;
    }
    if (!dark) {
      theme = lightTheme;
    }

    const root = document.getElementsByTagName("html")[0];
    root.style.cssText = theme.join(";");
  };

  const toggle = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.cssText = "transition: background .1s ease";

    setDark(!dark);
  };

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

const lightTheme = [
  "--md-sys-color-primary: var(--md-sys-color-primary-light)",
  "--md-sys-color-on-primary: var(--md-sys-color-on-primary-container-light)",
  "--md-sys-color-secondary: var(--md-sys-color-secondary-container-light)",
  "--md-sys-color-on-secondary: var(--md-sys-color-on-secondary-dark);",
  "--md-sys-color-surface-tint: var( --md-sys-color-surface-tint-light);",
  "--md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-light)",
  "--md-sys-color-tertiary: var(--md-sys-color-tertiary-light)",
  "--md-sys-color-on-surface: var(--md-sys-color-on-surface-light);",
  "--md-sys-color-on-error-container: var(--md-sys-color-on-error-container-light);",
  "--md-sys-color-error-container: var(--md-sys-color-error-container-light)",
  "--md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-light)",
  "--md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-light)",
  "--md-sys-color-link: var(--md-sys-color-link-light)",
  "--md-sys-color-surface: var(--md-sys-color-surface-light)",
  "--md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-light)",
  "--md-sys-color-warning-notification-background: var(--md-sys-color-warning-notification-background-light)",
  "--md-sys-color-on-warning-notification-background: var(--md-sys-color-warning-notification-background-dark)",
  "--md-sys-color-success-notification-background: var(--md-sys-color-success-notification-background-light)",
  "--md-sys-color-on-success-notification-background: var(--md-sys-color-success-notification-background-dark)",
  "--md-sys-color-error: var(--md-sys-color-error-light);",
  "--md-sys-color-warning-notification-icon-fill: var(--md-sys-color-warning-notification-icon-fill-light)",
  "--md-sys-color-error-container-background: var(--md-sys-color-error-container-background-light)",
  "--md-sys-color-chip-background: var(--md-sys-color-chip-background-light)",
  "--md-sys-color-box-shadow-top: var(--md-sys-color-box-shadow-top-light)",
  "--md-sys-color-box-shadow-bottom: var(--md-sys-color-box-shadow-bottom-light)",
  "--md-sys-color-navbar-box-shadow: var(--md-sys-color-navbar-box-shadow-light)",
  "--md-sys-color-bg: var(--md-sys-color-bg-light)",
  "--md-sys-color-card-bg: var(--md-sys-color-card-bg-light)",
  "--md-sys-color-skeleton-bg: var(--md-sys-color-skeleton-bg-light)",
  "--md-sys-color-text: var(--md-sys-color-text-light)",
  "--md-sys-effect-media: var(--md-sys-effect-media-light)",
  "--md-sys-card-bg-image-color: var(--md-sys-card-bg-image-color-light)",
];

const darkTheme = [
  "--md-sys-color-primary: var(--md-sys-color-primary-dark)",
  "--md-sys-color-on-primary: var(--md-sys-color-on-primary-container-dark)",
  "--md-sys-color-secondary: var(--md-sys-color-secondary-container-dark)",
  "--md-sys-color-on-secondary: var(--md-sys-color-on-secondary-light);",
  "--md-sys-color-surface-tint: var(--md-sys-color-surface-tint-dark);",
  "--md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-dark)",
  "--md-sys-color-on-surface: var(--md-sys-color-on-surface-dark);",
  "--md-sys-color-on-error-container: var(--md-sys-color-on-error-container-dark);",
  "--md-sys-color-error-container: var(--md-sys-color-error-container-dark)",
  "--md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-dark)",
  "--md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-dark)",
  "--md-sys-color-link: var(--md-sys-color-link-dark)",
  "--md-sys-color-surface: var(--md-sys-color-surface-dark)",
  "--md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-dark)",
  "--md-sys-color-tertiary: var(--md-sys-color-tertiary-dark)",
  "--md-sys-color-warning-notification-background: var(--md-sys-color-warning-notification-background-dark)",
  "--md-sys-color-on-warning-notification-background: var(--md-sys-color-warning-notification-background-light)",
  "--md-sys-color-success-notification-background: var(--md-sys-color-success-notification-background-dark)",
  "--md-sys-color-on-success-notification-background: var(--md-sys-color-success-notification-background-light)",
  "--md-sys-color-error: var(--md-sys-color-error-dark);",
  "--md-sys-color-warning-notification-icon-fill: var(--md-sys-color-warning-notification-icon-fill-dark)",
  "--md-sys-color-error-container-background: var(--md-sys-color-error-container-background-dark)",
  "--md-sys-color-chip-background: var(--md-sys-color-chip-background-dark)",
  "--md-sys-color-box-shadow-top: var(--md-sys-color-box-shadow-top-dark)",
  "--md-sys-color-box-shadow-bottom: var(--md-sys-color-box-shadow-bottom-dark)",
  "--md-sys-color-navbar-box-shadow: var(--md-sys-color-navbar-box-shadow-dark)",
  "--md-sys-color-bg: var(--md-sys-color-bg-dark)",
  "--md-sys-color-card-bg: var(--md-sys-color-card-bg-dark)",
  "--md-sys-color-skeleton-bg: var(--md-sys-color-skeleton-bg-dark)",
  "--md-sys-color-text: var(--md-sys-color-text-dark)",
  "--md-sys-effect-media: var(--md-sys-effect-media-dark)",
  "--md-sys-card-bg-image-color: var(--md-sys-card-bg-image-color-dark)",
];
