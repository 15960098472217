import { ArrowDownIcon, ArrowUpIcon, Pane, Text } from "evergreen-ui";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { master } from "../..";
import { Typography } from "@mui/material";

function VoteButtons({ upvotes, downvotes, isComment, _id, index }) {
  const user = useSelector((state) => state.user);
  const { userId, isGuest, token } = user;
  const upVoteButtonColor = "green  ";
  const downVoteButtonColor = "red";

  const [upvotesCount, setUpvotesCount] = useState(upvotes.length);
  const [downvotesCount, setDownvotesCount] = useState(downvotes.length);

  useEffect(() => {
    setUpvotesCount(upvotes.length);
    setDownvotesCount(downvotes.length);
  }, [upvotes, downvotes]);

  const [userUpvoted, setUserUpvoted] = useState(() => {
    if (!isGuest) {
      return upvotes.includes(userId);
    }
    return false;
  });

  const [userDownvoted, setUserDownvoted] = useState(() => {
    if (!isGuest) {
      return downvotes.includes(userId);
    }
    return false;
  });

  const [upvotedFillColor, setUpvotedFillColor] = useState(() => {
    if (userUpvoted) {
      return upVoteButtonColor;
    }
    return "currentColor";
  });

  const [downvotedFillColor, setDownvotedFillColor] = useState(() => {
    if (userDownvoted) {
      return downVoteButtonColor;
    }
    return "currentColor";
  });

  const handleUpvote = () => {
    if (isGuest) {
      return null;
    }
    if (userDownvoted) {
      resetDownVote();
    }

    if (!userUpvoted) {
      setUpvotedFillColor(upVoteButtonColor);
      setUserUpvoted(true);
      setUpvotesCount((prevCount) => prevCount + 1);
    }

    if (userUpvoted) {
      return resetUpvote(true);
    }
    master.dispatchVote(isComment, _id, "add-upvote", token, userId);
  };

  const handleDownvote = () => {
    if (isGuest) {
      return null;
    }
    if (userUpvoted) {
      resetUpvote();
    }

    if (!userDownvoted) {
      setDownvotedFillColor(downVoteButtonColor);
      setUserDownvoted(true);
      setDownvotesCount((prevCount) => prevCount + 1);
    }

    if (userDownvoted) {
      return resetDownVote(true);
    }
    master.dispatchVote(isComment, _id, "add-downvote", token, userId);
  };

  function resetUpvote(propagate) {
    setUpvotedFillColor("currentColor");
    setUserUpvoted(false);
    setUpvotesCount((prevCount) => prevCount - 1);
    if (propagate) {
      master.dispatchVote(isComment, _id, "remove-upvote", token, userId);
    }
  }

  function resetDownVote(propagate) {
    setDownvotedFillColor("currentColor");
    setUserDownvoted(false);
    setDownvotesCount((prevCount) => prevCount - 1);
    if (propagate) {
      master.dispatchVote(isComment, _id, "remove-downvote", token, userId);
    }
  }

  return (
    <Pane display="flex" gap="1.5em">
      <Pane
        display="flex"
        alignItems="center"
        onClick={handleUpvote}
        name="upvote"
      >
        <ArrowUpIcon fontSize="small" style={{ fill: upvotedFillColor }} />
        <Typography
          style={{ fontSize: "small", marginLeft: "0.2em", marginTop: "0.3em" }}
        >
          {upvotesCount}
        </Typography>
      </Pane>

      <Pane
        display="flex"
        alignItems="center"
        id="downvote"
        onClick={handleDownvote}
      >
        <ArrowDownIcon fontSize="small" style={{ fill: downvotedFillColor }} />
        <Typography
          style={{ fontSize: "small", marginLeft: "0.2em", marginTop: "0.3em" }}
        >
          {downvotesCount}
        </Typography>
      </Pane>
    </Pane>
  );
}

export default VoteButtons;
