import { Paragraph, Text, toaster } from "evergreen-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeOldestNotification } from "../../store/user";
import { duration } from "@mui/material";

function Notification() {
  const notifications = useSelector((state) => state.user.notifications);
  const dispatch = useDispatch();
  let titleColor;
  let descriptionColor;

  useEffect(() => {
    if (notifications.length > 0) {
      const { type, message, description } =
        notifications[notifications.length - 1];

      if (type === "warning") {
        titleColor = "var(--md-sys-color-on-warning-notification-background)";
      }

      if (type === "success") {
        titleColor = "var(--md-sys-color-on-success-notification-background)";
        descriptionColor = "var(--md-sys-color-on-secondary)";
      }

      if (type === "danger") {
        titleColor = "var(--md-sys-color-error)";
        descriptionColor = "var(--md-sys-color-on-secondary)";
      }

      toaster[type](<FormatNotificationTitle message={message} />, {
        description: (
          <FormatNotificationDescription description={description} />
        ),
        // duration: 90000,
      });
      dispatch(removeOldestNotification(null));
    }
  }, [notifications]);

  return null;

  function FormatNotificationTitle({ message }) {
    return <Text color={titleColor}>{message}</Text>;
  }

  function FormatNotificationDescription({ description }) {
    return <Paragraph color={descriptionColor}>{description}</Paragraph>;
  }
}

export default Notification;
