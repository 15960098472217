import { Checkbox, Pane, Text } from "evergreen-ui";
import { useDispatch, useSelector } from "react-redux";
import { setShouldShowPreviews } from "../store/posts";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

function Settings() {
  const showCardPreviews = useSelector(
    (state) => state.posts.shouldShowCardPreviews
  );

  const dispatch = useDispatch();

  return (
    <Pane padding="8px">
      <FormGroup sx={{ color: "var(--md-sys-color-text)" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showCardPreviews}
              onChange={(e) => dispatch(setShouldShowPreviews())}
              size="small"
            />
          }
          label={<Text>Display content previews and media.</Text>}
          sx={{ padding: "8px" }}
        />
        <FormControlLabel
          size="small"
          control={<Switch checked={true} size="small" />}
          label={<Text>Show Naira-X AI posts</Text>}
          sx={{ padding: "8px" }}
        />
      </FormGroup>
    </Pane>
  );
}

export default Settings;
