import { Divider, Drawer, IconButton, Typography } from "@mui/material";
import { master } from "../..";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ShareIcon from "@mui/icons-material/Share";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TelegramIcon from "@mui/icons-material/Telegram";
import BlockIcon from "@mui/icons-material/Block";
import TrimmedContent from "../TrimmedContent";
import VoteButtons from "./Votebuttons";
import CommentButton from "./CommentButton";
import PostViews from "./PostViews";
import { Pane, Text } from "evergreen-ui";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RenderMedia from "./RenderMedia";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const ICONS = [
  <ShareIcon />,
  <NotificationsActiveIcon />,
  <PersonAddIcon />,
  <BlockIcon />,
  <ReportProblemIcon />,
];

function MainCard({ data, index, lastIndex, isGuest }) {
  const showCardPreviews = useSelector(
    (state) => state.posts.shouldShowCardPreviews
  );

  const { userId, following } = useSelector((state) => state.user);

  let commentMode = false;

  const [showCardOptions, setShowCardOptions] = useState(false);

  let {
    title,
    category,
    owner,
    date,
    content,
    media,
    mediaType,
    upvotes,
    downvotes,
    comments,
    views,
    replies,
    postId,
    shares,
    _id,
  } = data;

  const [userShared, setUserShared] = useState(shares.includes(userId));
  const [userIsFollowing, setUserIsFollowing] = useState(
    following.includes(owner)
  );

  if (!title && !category) {
    commentMode = true;
  }

  const {
    ref: mainContentRef,
    inView: mainContentInView,
    entry: mainContentEntry,
  } = useInView({
    triggerOnce: true,
    delay: 7000,
    threshold: 1,
    rootMargin: "-10% 0% -20% 0%",
    onChange: (mainContentInView, mainContentEntry) => {
      if (mainContentEntry) {
        if (mainContentInView) {
          if (!commentMode) {
            // master.registerImpression(_id.current, "post", category);
          } else {
            master.registerImpression(_id.current, "comment");
          }
        }
      }
    },
  });

  const {
    ref: contentRef,
    inView: contentInView,
    entry,
  } = useInView({
    triggerOnce: true,
    threshold: 1,
    root: null,
  });

  _id = useRef(_id);

  useEffect(() => {
    if (entry && contentInView && index === lastIndex) {
      master.refillStore();
    }
  });

  const displayUserProfile = (e) => {
    const username = e.target.textContent;
    master.getUserProfile(username);
  };

  const handleDrawerClick = (e) => {
    const id = Number(e.currentTarget.getAttribute("id"));
    if (id === 0) {
      if (userShared) {
        master.newContentShare(userId, _id.current, false, commentMode);
        setUserShared(false);
      } else {
        master.newContentShare(userId, _id.current, true, commentMode);
        setUserShared(true);
      }
    }
    if (id === 2) {
      if (userIsFollowing) {
        master.newFollowing(userId, owner, false);
        setUserIsFollowing(false);
      } else {
        master.newFollowing(userId, owner, true);
        setUserIsFollowing(true);
      }
    }
  };

  return (
    <Pane ref={mainContentRef}>
      <Pane
        key={index}
        paddingTop={8}
        paddingBottom={1}
        maxWidth={"600px"}
        elevation={1}
        marginLeft="auto"
        marginRight="auto"
        width={"100%"}
        marginBottom={index === lastIndex ? "2em" : "2em"}
        ref={contentRef}
        borderRadius="5px"
        background="var(--md-sys-color-card-bg)"
      >
        <IconButton
          aria-label="more options"
          edge="end"
          color="inherit"
          sx={{ float: "right", opacity: 0.2, marginRight: "8px" }}
          onClick={() => {
            setShowCardOptions(true);
          }}
        >
          <MoreHorizTwoToneIcon owner={owner} />
        </IconButton>

        <Drawer
          anchor={"bottom"}
          open={showCardOptions}
          onClose={() => {
            setShowCardOptions(false);
          }}
        >
          <Pane>
            <List>
              {[
                `${userShared ? "Unshare" : "Share"} this post`,
                "Turn on post notifications",
                `${userIsFollowing ? "Unfollow" : "Follow"} ${owner}`,

                `Block posts from ${owner}`,
                "Report",
              ].map((text, index) => (
                <ListItem
                  key={text}
                  id={index}
                  disablePadding
                  onClick={handleDrawerClick}
                >
                  <ListItemButton disabled={isGuest}>
                    <ListItemIcon>{ICONS[index] || <InboxIcon />}</ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{ color: "var(--md-sys-color-text)" }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Pane>
        </Drawer>

        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          marginLeft={!commentMode ? "3.3em" : 0}
        >
          {!commentMode ? (
            <Text
              color="var(--md-sys-color-text)"
              fontSize="11.5pt"
              sx={{
                fontSize: "11.5pt",
                // fontWeight: "700",
                color: "var(--md-sys-color-text)",
                marginBottom: "-0.5em",
              }}
            >
              {title}
            </Text>
          ) : null}
          <Pane
            marginLeft={"auto"}
            marginRight={"auto"}
            marginTop={"0.5em"}
            opacity="0.5"
            color="var(--md-sys-color-on-primary)"
          >
            {!commentMode ? (
              <Typography fontSize={"small"} sx={{ marginBottom: "-5px" }}>
                {
                  <Pane onClick={displayUserProfile} display="inline">
                    {owner}
                  </Pane>
                }{" "}
                • {category} • {master.getTimeElapsed(date)}
              </Typography>
            ) : (
              <Text>
                <Pane onClick={displayUserProfile} display="inline">
                  {owner}
                </Pane>{" "}
                • {master.getTimeElapsed(date)}
              </Text>
            )}
          </Pane>
        </Pane>

        {showCardPreviews ? (
          <Pane paddingTop="4px">
            <Divider variant="middle" />
            <TrimmedContent content={content} />
            {media.length > 0 && entry && contentInView ? (
              <RenderMedia mediaArray={media} mediaType={mediaType} />
            ) : null}
          </Pane>
        ) : null}

        <Divider variant="middle" sx={{ marginTop: "5px" }} />

        <Pane
          display="flex"
          justifyContent="center"
          gap="2.5em"
          color="var(--md-sys-color-text)"
          padding={8}
        >
          <PostViews views={views} />
          <VoteButtons
            index={index}
            upvotes={upvotes}
            downvotes={downvotes}
            isComment={commentMode}
            _id={_id.current}
          />
          <CommentButton
            comments={!commentMode ? comments : replies}
            postId={postId ? postId : _id}
            parentCommentId={commentMode ? _id : null}
            postTitle={title}
            owner={owner}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

export default MainCard;
