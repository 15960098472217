import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { Typography } from "@mui/material";
import { Pane } from "evergreen-ui";

function PostViews({ views }) {
  let count = views.length;
  return (
    <Pane display="flex" alignItems="center">
      <BarChartTwoToneIcon fontSize="small" />
      <Typography
        style={{ fontSize: "small", marginLeft: "0.2em", marginTop: "0.5em" }}
      >
        {count}
      </Typography>
    </Pane>
  );
}

export default PostViews;
