import { Checkbox, Pane, Text, TextInputField, toaster } from "evergreen-ui";
import { useState } from "react";
import { master } from "../..";

function LoginForm() {
  const [checked, setChecked] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    staySignedIn: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    master.formsWorker.postMessage({
      action: "update-form-data",
      data: updatedFormData,
    });
    if (name === "staySignedIn") {
      if (value === false) {
        master.dispatchNotification(
          "notify",
          "You'll be signed out automatically as soon as your current session ends."
        );
      } else {
        master.dispatchNotification(
          "notify",
          "Unless you logout, you'll stay signed in on this device."
        );
      }
      toaster.closeAll();
    }
  };

  return (
    <Pane>
      <TextInputField
        label="Username"
        name="username"
        onChange={handleChange}
      />
      <TextInputField
        label="Password"
        type="password"
        name="password"
        onChange={handleChange}
      />
      <Checkbox
        label="Stay signed in on this device?"
        checked={checked}
        onChange={(e) => {
          const selected = e.target.checked;
          e = {};
          e.target = { name: "staySignedIn", value: selected };
          setChecked(selected);
          handleChange(e);
        }}
      />
      <Text className="label-medium">Forgot password?</Text>
    </Pane>
  );
}

export default LoginForm;
