import { MediaIcon, Pane, SendMessageIcon, Text } from "evergreen-ui";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import { useRef, useState } from "react";
import MainCard from "./MainCard";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useSelector } from "react-redux";
import { master } from "../..";

function CommentButton({
  comments,
  postId,
  postTitle,
  parentCommentId,
  owner,
}) {
  let count = comments.length;
  const maxTitleLength = 25;
  const maxRepliesTitleLength = 40;

  let repliesText = `Replies to ${owner} on ${master.currentPostTitle}`;
  repliesText =
    repliesText.length > maxRepliesTitleLength
      ? `${repliesText.substring(0, maxRepliesTitleLength)}...`
      : repliesText;

  parentCommentId = parentCommentId ? parentCommentId.current : null;

  if (postId.hasOwnProperty("current")) {
    postId = postId.current;
  }

  if (postTitle) {
    postTitle =
      postTitle.length > maxTitleLength
        ? `${postTitle.substring(0, maxTitleLength)}...`
        : postTitle;
    master.currentPostTitle = postTitle;
  }

  const [showDialog, setShowDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [renderComments, setRenderComments] = useState(false);

  const username = useSelector((state) => state.user.userId);

  const fileInputRef = useRef(null);

  const handleMediaIconClick = () => {
    // Trigger the file input dialog when the media icon is clicked
    fileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    setSelectedFile(selectedFile);
  };

  function handleClick() {
    setShowDialog(true);
    setRenderComments(true);
  }

  function handleCloseDialog() {
    setShowDialog(false);
    setRenderComments(false);
  }

  function handleSendComment() {
    if (comment.length > 0) {
      const data = {
        body: comment,
        postId,
        parentCommentId,
        file: selectedFile,
      };
      setComment("");
      setSelectedFile([]);

      master.createNewContent(data, "comment");
    }
  }

  return (
    <Pane display="flex" alignItems="center">
      <Pane onClick={handleClick} display="flex">
        <ForumTwoToneIcon fontSize="small" />
        <Typography
          style={{ fontSize: "small", marginLeft: "0.2em", marginTop: "0.2em" }}
        >
          {count}
        </Typography>
      </Pane>

      <Dialog
        open={showDialog}
        fullScreen={true}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialogContent-root": {
            padding: 0,
            backgroundColor: "var(--md-sys-color-bg)",
          },
          zIndex: 99,
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "var(--md-sys-color-primary)",
          }}
        >
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <ArrowBackTwoToneIcon fill="var(--md-sys-color-on-primary)" />
          </IconButton>
          <Text variant="body2" marginLeft="auto" fontSize="11.9px">
            {postTitle ? `Comments on ${postTitle}` : repliesText}
          </Text>{" "}
        </DialogTitle>

        <DialogContent>
          {/* <SortContentControl /> */}
          {renderComments ? (
            <Pane>
              {comments.map((comment, index) => (
                <MainCard key={index} index={index} data={comment} />
              ))}
              <Pane
                display="flex"
                justifyContent="center"
                color="var(--md-sys-color-text)"
              >
                .
              </Pane>
            </Pane>
          ) : null}
        </DialogContent>

        <Pane>
          <TextField
            multiline
            id="outlined-basic"
            placeholder={`Comment as ${username}`}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <Pane display="flex" gap="0.5em">
                  <label htmlFor="file-input" className="file-input-label">
                    <IconButton
                      aria-label="add-media"
                      edge="end"
                      onClick={handleMediaIconClick}
                    >
                      <MediaIcon fill="var(--md-sys-color-on-primary)" />
                    </IconButton>
                  </label>
                  <span className="file-name">
                    {/* {selectedFile ? selectedFile.name : "No file chosen"} */}
                  </span>
                  <input
                    id="file-input"
                    ref={fileInputRef}
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .mp4, .mov, .webp"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <IconButton
                    aria-label="send"
                    edge="end"
                    onClick={handleSendComment}
                  >
                    <SendMessageIcon fill="var(--md-sys-color-on-primary)" />
                  </IconButton>
                </Pane>
              ),
              classes: {
                focused: "custom-focused",
              },
            }}
            onChange={handleInputChange}
            name="comment-body"
            sx={{ background: "var(--md-sys-color-primary)" }}
            value={comment}
          />
        </Pane>
      </Dialog>
    </Pane>
  );
}

export default CommentButton;
