import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Image, Pane } from "evergreen-ui";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube";
import { IconButton, Slider } from "@mui/material";
import VolumeOffTwoToneIcon from "@mui/icons-material/VolumeOffTwoTone";
import VolumeUpTwoToneIcon from "@mui/icons-material/VolumeUpTwoTone";
import { master } from "../..";

function RenderMedia({ mediaArray, mediaType }) {
  const [progress, setProgress] = useState(0);

  const playerRef = useRef(null);
  const playerTime = useRef(0);

  const {
    ref: mediaRef,
    inView: mediaInView,
    entry,
  } = useInView({
    threshold: 1,
    root: null,
  });

  let imageStyle = {
    width: "100%",
    height: "100%",
    filter: entry && mediaInView ? "none" : "blur(1px)",
  };

  const handlePlayerClick = async () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        player.play().then(() => {
          // setIsVideoPlaying(true);
        });
      } else {
        player.pause();
        // setIsVideoPlaying(false);
      }
    }
  };

  const handlePlayerMute = (e) => {
    const player = playerRef.current;
    if (player) {
      const volume = player.volume();
      let newVolume = volume > 0 ? 0 : 1;
      player.volume(newVolume);
      master.persistMediaVolume(newVolume);
    }
  };

  const handlePlayerSeek = (e) => {
    const player = playerRef.current;
    const newValue = e.target.value;

    if (player) {
      const newTime = (newValue / 100) * player.duration(); // Calculate the new time based on the slider value
      player.currentTime(newTime); // Seek to the new time
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("click", () => {
      handlePlayerClick();
    });

    player.on("touchstart", () => {
      handlePlayerClick();
    });

    // Add an event listener to update the progress
    player.on("timeupdate", () => {
      const currentTime = player.currentTime(); // Current playback time
      const duration = player.duration(); // Total duration
      const calculatedProgress = (currentTime / duration) * 100;
      setProgress(calculatedProgress);
    });
  };

  const renderMedia = () => {
    return mediaArray.map((mediaUrl, index) => (
      <Pane
        key={index}
        style={{
          width: "100%",
          position: "relative",
        }}
        ref={mediaRef}
      >
        {mediaType === "image" ? (
          <Pane>
            <Image src={mediaUrl} alt="Image" style={imageStyle}></Image>
          </Pane>
        ) : mediaType === "video" ? (
          <Pane position="relative">
            <VideoJS
              options={{
                responsive: true,
                muted: false,
                fluid: true,
                sources: [
                  {
                    src: mediaUrl,
                    type: isYouTubeLink(mediaUrl)
                      ? "video/youtube"
                      : "video/mp4",
                  },
                ],
                aspectRatio: "16:9",
                preload: "true",
                play: entry && mediaInView ? true : false,
                autoplay: "auto",
                loop: true,
              }}
              config={{
                youtube: {
                  playerVars: { cc_load_policy: 1 },
                },
              }}
              onReady={handlePlayerReady}
            />

            <Pane
              display="flex"
              justifyContent="flex-end"
              position="relative"
              marginTop="-3.6em"
            >
              <IconButton onClick={handlePlayerMute}>
                {" "}
                {master.getMediaVolume() < 1 ? (
                  <VolumeOffTwoToneIcon
                    sx={{
                      color: "#9ad680",
                      opacity: 0.5,
                    }}
                  />
                ) : (
                  <VolumeUpTwoToneIcon
                    sx={{
                      color: "#9ad680",
                      opacity: 0.5,
                    }}
                  />
                )}
              </IconButton>
            </Pane>
            <Slider
              size="small"
              value={progress}
              onChange={handlePlayerSeek}
              sx={{
                color: "var(--md-sys-color-on-primary)",
              }}
            />
          </Pane>
        ) : null}
      </Pane>
    ));
  };

  return <Pane>{mediaArray.length > 0 && renderMedia()}</Pane>;
}

function VideoJS({ options, onReady }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const volume = master.getMediaVolume() ? master.getMediaVolume() : 0;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");

        player.volume(volume);
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;
      if (player.options().play !== options.play) {
        if (options.play) {
          player.play();
        } else {
          player.pause();
        }
        player.volume(volume);
        player.options(options);
      }
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

function isYouTubeLink(url) {
  const youtubeRegex = /https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+/;

  return youtubeRegex.test(url);
}

export default RenderMedia;
