import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posts: [],
  temp: [],
  hasMorePosts: true,
  shouldShowCardPreviews: (() => {
    const showPreviews = localStorage.getItem("naira-x-show-previews");
    if (showPreviews === "false") {
      return false;
    }
    return true;
  })(),
};

const updateCommentTree = (comments, updatedContent) => {
  return comments.map((comment) => {
    if (comment._id === updatedContent._id) {
      return {
        ...comment,
        upvotes: updatedContent.upvotes,
        downvotes: updatedContent.downvotes,
      };
    }

    if (comment.replies) {
      const updatedReplies = updateCommentTree(comment.replies, updatedContent);
      return {
        ...comment,
        replies: updatedReplies,
      };
    }

    return comment;
  });
};

const appendCommentToCommentTree = (comments, newComment) => {
  return comments.map((comment) => {
    if (comment._id === newComment.parentCommentId) {
      return {
        ...comment,
        replies: [...(comment.replies || []), newComment],
      };
    } else if (comment.replies && comment.replies.length > 0) {
      const updatedReplies = appendCommentToCommentTree(
        comment.replies,
        newComment
      );
      if (updatedReplies !== comment.replies) {
        // If replies were updated, return a new comment object with the updated replies
        return {
          ...comment,
          replies: updatedReplies,
        };
      }
    }
    return comment;
  });
};

const posts = createSlice({
  name: "posts",
  initialState,
  reducers: {
    addPostsToStore: (state, action) => {
      const posts = action.payload;
      if (posts.length > 0) {
        posts.forEach((post) => {
          state.posts.push(post);
        });
      }
    },

    emptyPostsInStore: (state, action) => {
      state.posts = [];
    },

    cachePostsToTemp: (state, action) => {
      const posts = state.posts;
      state.temp.push(posts);
    },

    popLastCachedPosts: (state, action) => {
      const temp = state.temp;
      temp.pop();
      state.temp = temp;
    },

    updateVotes: (state, action) => {
      const { updatedContent, isComment } = action.payload;
      const updatedPosts = state.posts.map((post) => {
        if (isComment) {
          if (post._id === updatedContent.postId) {
            const updatedComments = updateCommentTree(
              post.comments,
              updatedContent
            );
            return {
              ...post,
              comments: updatedComments,
            };
          }
        } else {
          if (post._id === updatedContent._id) {
            // Update top-level post
            return {
              ...post,
              upvotes: updatedContent.upvotes,
              downvotes: updatedContent.downvotes,
            };
          }
        }
        return post;
      });

      return {
        ...state,
        posts: updatedPosts,
      };
    },
    updateComments: (state, action) => {
      const newComment = action.payload;
      newComment.replies = [];
      const updatedPosts = state.posts.map((post) => {
        if (post._id === newComment.postId) {
          if (newComment.parentCommentId === null) {
            const updatedComments = [...post.comments, newComment];
            return {
              ...post,
              comments: updatedComments,
            };
          } else {
            const updatedComments = appendCommentToCommentTree(
              post.comments,
              newComment
            );
            return {
              ...post,
              comments: updatedComments,
            };
          }
        }
        return post;
      });

      return {
        ...state,
        posts: updatedPosts,
      };
    },
    newPostView: (state, action) => {
      const { postId, userId } = action.payload;

      const post = state.posts.find((post) => post._id === postId);

      if (post) {
        post.views.push(userId);
      }
    },
    setHasMorePosts: (state, action) => {
      state.hasMorePosts = action.payload;
    },
    setShouldShowPreviews: (state) => {
      state.shouldShowCardPreviews = !state.shouldShowCardPreviews;
      localStorage.setItem(
        "naira-x-show-previews",
        state.shouldShowCardPreviews
      );
    },
  },
});

export const {
  addPostsToStore,
  updateVotes,
  updateComments,
  newPostView,
  emptyPostsInStore,
  setHasMorePosts,
  setShouldShowPreviews,
  cachePostsToTemp,
  popLastCachedPosts,
} = posts.actions;

export default posts.reducer;
