import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  websocketWorker: null,
  authWorker: null,
  formsWorker: null,
  showConfetti: false,
  showHeader: true,
  isProfileModeActive: false,
  isLoadingProfile: false,
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    setWebsocketWorker: (state, action) => {
      state.websocketWorker = action.payload;
    },

    setAuthWorker: (state, action) => {
      state.authWorker = action.payload;
    },

    setFormsWorker: (state, action) => {
      state.formsWorker = action.payload;
    },

    setShowConfetti: (state, action) => {
      state.showConfetti = action.payload;
    },

    setShowHeader: (state, action) => {
      state.showHeader = action.payload;
    },
    setDisplayProfile: (state, action) => {
      state.displayProfile = action.payload;
    },
    setIsProfileModeActive: (state, action) => {
      state.isProfileModeActive = action.payload;
    },
    setIsLoadingProfile: (state, action) => {
      state.isLoadingProfile = action.payload;
    },
  },
});

export const {
  setWebsocketWorker,
  setAuthWorker,
  setShowConfetti,
  setShowHeader,
  setDisplayProfile,
  setIsProfileModeActive,
  setIsLoadingProfile
} = application.actions;

export default application.reducer;
