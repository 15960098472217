import { store } from "..";
import {
  setAuthWorker,
  setIsLoadingProfile,
  setIsProfileModeActive,
  setShowConfetti,
  setShowHeader,
  setWebsocketWorker,
} from "../store/application";
import { updatePostCache } from "../store/cache";
import {
  addPostsToStore,
  cachePostsToTemp,
  emptyPostsInStore,
  newPostView,
  popLastCachedPosts,
  setHasMorePosts,
  updateComments,
  updateVotes,
} from "../store/posts";
import {
  addNotification,
  appendCurrentlyViewing,
  appendProfile,
  popLastProfile,
  popLastViewedProfile,
  setCreatedAt,
  setEmail,
  setFollowers,
  setFollowing,
  setGuestIdDescription,
  setInterests,
  setIsLoaded,
  setLocation,
  setProfile,
  setUserToken,
  setVerificationStatus,
  set_bio,
  set_is_guest,
  set_token,
  set_user_id,
  setprofilePictureUrl,
  updateFollowing,
} from "../store/user";
import bcrypt from "bcryptjs";

class Master {
  constructor() {
    this.authWorker = undefined;
    this.categories = undefined;
    this.currentPostTitle = undefined;
    this.currentDialog = undefined;
    this.fetchWorker = undefined;
    this.formsWorker = undefined;
    this.isCategoryActive = false;
    this.postsWorker = undefined;
    this.userId = undefined;
    this.websocketWorker = undefined;
    this.searchString = undefined;

    this.loginAttempts = 0;
    this.isMasterUnstable = false;
    this.hasPendingFormChanges = false;
    this.hasPostInReview = false;
    this.searchMode = false;

    this.registerWorkers();
  }

  registerWorkers() {
    try {
      this.websocketWorker = new Worker(
        new URL("./workers/WebsocketWorker/websocketWorker.js", import.meta.url)
      );
      this.authWorker = new Worker(
        new URL("./workers/AuthWorker/authWorker.js", import.meta.url)
      );
    } catch (error) {
      //TODO: handle error here
      this.isMasterUnstable = true;
    }
  }

  initWorkers() {
    if (this.isMasterUnstable) {
      //TODO: more error handle
      // cannot init workers, somethings wrong
      return;
    }

    const webSocketAddress = process.env.REACT_APP_WEBSOCKET_URL;

    this.websocketWorker.postMessage({
      action: "start",
      data: webSocketAddress,
    });

    this.websocketWorker.addEventListener(
      "message",
      this.handlewebSocketWorkerMessage
    );
  }

  initFormsWorker(type) {
    this.formsWorker = new Worker(
      new URL("./workers/FormsWorker/formsWorker.js", import.meta.url)
    );

    this.formsWorker.postMessage({ action: "start", data: type });

    this.formsWorker.hasValidatedForm = false;
    this.formsWorker.isDisabled = false;
    this.formsWorker.timeDisabled = 10;
    this.formsWorker.type = type;

    this.formsWorker.addEventListener("message", this.handleFormsWorkerMessage);
  }

  initPostsWorker() {
    this.postsWorker = new Worker(
      new URL("./workers/PostsWorker/postsWorker.js", import.meta.url)
    );

    const { user } = store.getState();
    const { interests, location, following } = user;

    this.postsWorker.postMessage({
      action: "start",
      data: { interests, location, following },
    });

    this.postsWorker.startDate = undefined;
    this.postsWorker.endDate = undefined;

    this.postsWorker.addEventListener("message", this.handlePostsWorkerMessage);
  }

  handleFormsWorkerMessage = (message) => {
    const { action, data } = message.data;
    if (action === "notification") {
      const { type, message, description } = data;
      this.dispatchNotification(type, message, description);
    }

    if (action === "register-new-user") {
      this.registerNewUser(data);
    }

    if (action === "login-user") {
      this.loginUser(data);
    }

    if (action === "create-new-post") {
      this.createNewContent(data, "post");
    }
  };

  handlewebSocketWorkerMessage = (message) => {
    const { action, data } = message.data;

    switch (action) {
      case "register-self":
        this.registerWebsocketWorkerToReduxStore(data);
        break;
      case "email-or-username-already-exists":
        this.userAlreadyExists();
        break;
      case "account-creation-error":
        this.accountCreationError();
        break;
      case "account-creation-successful":
        this.signInNewUser(data);
        break;
      case "user-not-found":
        this.loginAttempt(action);
        break;
      case "invalid-login-password":
        this.loginAttempt(action);
        break;
      case "sign-in-user":
        this.signInOldUser(data, true);
        break;
      case "set-guest-id":
        this.setGuestId(data);
        break;
      case "confirm-user-hash":
        this.confirmUserHash(data);
        break;
      case "set-registered-user":
        this.signInOldUser(data, false);
        break;
      case "set-refresh-token":
        this.setRefreshToken(data);
        break;
      case "set-websocket-connection":
        this.confirmWebsocketConnection(data);
        break;
      case "handle-posts":
        this.handlePosts(data);
        break;
      case "apply-vote":
        this.applyVote(data);
        break;
      case "apply-view":
        this.applyView(data);
        break;
      case "apply-comment":
        this.applyComment(data);
        break;
      case "handle-profile-data":
        this.handleProfileData(data);
        break;
      default:
    }
  };

  handleAuthWorkerMessage = (message) => {
    const { action, data } = message.data;

    switch (action) {
      case "register-self":
        this.registerAuthWorkerToReduxStore(data);
        break;
      case "sign-in-guest":
        this.signInGuest(data);
        break;
      case "get-guest-id":
        this.getGuestId();
        break;
      case "verify-refresh-token":
        this.verifyRefreshToken(data);
        break;
      case "resign-refresh-token":
        this.resignRefreshToken();
        break;
      default:
    }
  };

  handlePostsWorkerMessage = (message) => {
    const { action, data } = message.data;

    switch (action) {
      case "get-posts":
        this.getPosts(data);
        break;
      case "add-posts-to-store":
        this.addPostsToStore(data);
        break;
      default:
    }
  };

  handleFetchWorkerMessage = (message) => {
    const { action, data } = message.data;
    if (action === "moderation-failed") {
      this.handleFailedModeration();
    }

    if (action === "post-file-upload-fail") {
      this.handleFailedFileUpload();
    }

    if (action === "content-created-successfully") {
      this.handleContentCreationSuccess(data);
    }

    if (action === "create-new-post") {
      this.createNewPost(data);
    }

    if (action === "internal-error-occured") {
      this.handleCreatePostInternalError();
    }
  };

  terminateFormsWorker() {
    this.formsWorker.terminate();
  }

  watchDialog(setState) {
    this.currentDialog = setState;
    return null;
  }

  handlePosts(data) {
    const { latest, trending } = data;
    if (latest) {
      if (latest.length > 0) {
        this.postsWorker.endDate = latest[latest.length - 1].date;
        if (!this.postsWorker.startDate) {
          this.postsWorker.startDate = latest[0].date;
        }
      }
    }

    this.postsWorker.postMessage({ action: "handle-posts", data });
  }

  refillStore(_data) {
    const { user, application } = store.getState();
    const { interests } = user;
    const { isProfileModeActive } = application;

    const data = {
      interests,
      location: "",
      following: [],
      preferredStrategy: "",
      startDate: this.postsWorker.startDate,
      endDate: this.postsWorker.endDate,
      userId: this.userId,
      search: this.searchMode ? this.searchString : null,
    };
    let profile = undefined;

    if (isProfileModeActive) {
      let currentlyViewing = user.currentlyViewing;
      profile = currentlyViewing[currentlyViewing.length - 1];
      data.profile = profile;
    }

    data.categories = this.categories;

    if (_data) {
      const { sortBy } = _data;
      data.sortBy = sortBy;
    }

    this.websocketWorker.postMessage({ action: "get-posts", data });
  }

  refreshPosts(categories) {
    store.dispatch(emptyPostsInStore());
    const data = { categories };

    if (categories.length > 1) {
      this.categories = categories;
      this.isCategoryActive = true;
    } else {
      this.isCategoryActive = false;
      this.categories = undefined;
    }

    this.websocketWorker.postMessage({ action: "get-posts", data });
  }

  confirmWebsocketConnection(id) {
    this.websocketWorker.id = id;
    const guestId = localStorage.getItem("naira-x-guest-id");
    const userId = localStorage.getItem("naira-x-user-id");
    const guestHash = localStorage.getItem("naira-x-guest-hash");
    const userHash = localStorage.getItem("naira-x-user-hash");
    const refreshToken = localStorage.getItem("naira-x-refresh-token");
    const staySignedIn = localStorage.getItem("naira-x-stay-signed-in");

    const data = {
      guestId,
      userId,
      guestHash,
      userHash,
      refreshToken,
      staySignedIn,
    };

    this.authWorker.postMessage({ action: "start", data });
    this.authWorker.addEventListener("message", this.handleAuthWorkerMessage);
  }

  setRefreshToken(data) {
    localStorage.setItem("naira-x-refresh-token", data);
    this.authWorker.postMessage({ action: "reset-refresh-token", data });
    this.authWorker.postMessage({ action: "set-up-environment" });
  }

  resignRefreshToken() {
    const username = localStorage.getItem("naira-x-user-id");

    if (!username) {
      return this.authWorker.postMessage({ action: "guest-fallback" });
    }

    this.websocketWorker.postMessage({
      action: "resign-refresh-token",
      data: username,
    });
  }

  confirmUserHash() {
    this.authWorker.postMessage({ action: "confirm-user-hash" });
  }

  verifyRefreshToken(data) {
    this.websocketWorker.postMessage({ action: "verify-refresh-token", data });
  }

  getGuestId() {
    this.websocketWorker.postMessage({ action: "get-guest-id" });
  }

  registerWebsocketWorkerToReduxStore(status) {
    store.dispatch(setWebsocketWorker(status));
  }

  registerAuthWorkerToReduxStore(status) {
    store.dispatch(setAuthWorker(status));
    if (status === true && this.websocketWorker) {
      this.authWorker.postMessage({ action: "set-up-environment", data: null });
    }
  }

  signInGuest(data) {
    const guestId = data;
    const guestIdDescription = localStorage.getItem(
      "naira-x-guest-description"
    );

    this.setUpStoreForGuest(guestId, guestIdDescription);
  }

  setGuestId(data) {
    const { name, description } = data;
    this.setUpStoreForGuest(name, description);

    localStorage.setItem("naira-x-guest-id", name);
    localStorage.setItem("naira-x-guest-description", description);
    const hash = bcrypt.hashSync(name);
    localStorage.setItem("naira-x-guest-hash", hash);
  }

  signInNewUser(data) {
    this.dispatchNotification(
      "success",
      "Account created successfully",
      "Kindly verify your email address to complete activation."
    );
    this.currentDialog(false);
    const { email, username, state, token, refreshToken } = data;

    store.dispatch(set_user_id(username));
    store.dispatch(set_is_guest(false));
    store.dispatch(setEmail(email));
    store.dispatch(set_token(token));
    store.dispatch(setVerificationStatus(false));
    store.dispatch(setLocation(state));

    localStorage.setItem("naira-x-user-id", username);
    localStorage.setItem("naira-x-refresh-token", refreshToken);

    const hash = bcrypt.hashSync(username);
    localStorage.setItem("naira-x-user-hash", hash);
  }

  signInOldUser(data, login) {
    if (login) {
      this.dispatchNotification("success", "Login Success");
      this.currentDialog(false);
    }

    const {
      username,
      location,
      token,
      refreshToken,
      profilePicture,
      bio,
      lastActivity,
      interests,
      followers,
      following,
      isVerified,
      createdAt,
    } = data;

    store.dispatch(set_user_id(username));
    store.dispatch(set_is_guest(false));

    store.dispatch(setVerificationStatus(false));
    store.dispatch(setLocation(location));
    store.dispatch(setprofilePictureUrl(profilePicture));
    if (interests.length > 0) {
      store.dispatch(setInterests(interests));
    }
    store.dispatch(setVerificationStatus(isVerified));
    store.dispatch(set_bio(bio));
    store.dispatch(setCreatedAt(createdAt));
    store.dispatch(setFollowers(followers));
    store.dispatch(setFollowing(following));
    store.dispatch(set_token(token));

    if (login) {
      localStorage.setItem("naira-x-refresh-token", refreshToken);
      const hash = bcrypt.hashSync(username);
      localStorage.setItem("naira-x-user-hash", hash);
      localStorage.setItem("naira-x-user-id", username);
    }
    if (!login) {
      store.dispatch(setIsLoaded(true));
      this.initPostsWorker();
    }
  }

  setUpStoreForGuest(guestId, guestIdDescription) {
    store.dispatch(set_user_id(guestId));
    store.dispatch(setGuestIdDescription(guestIdDescription));
    store.dispatch(setIsLoaded(true));
    this.initPostsWorker();
  }

  getPosts(data) {
    this.websocketWorker.postMessage({ action: "get-posts", data });
  }

  addPostsToStore(data) {
    if (data.length === 0) {
      store.dispatch(setHasMorePosts(false));
    } else {
      store.dispatch(setHasMorePosts(true));
    }

    store.dispatch(addPostsToStore(data));
  }

  dispatchNotification(type, message, description) {
    store.dispatch(addNotification({ type, message, description }));
  }

  registerNewUser(data) {
    this.websocketWorker.postMessage({ action: "register-new-user", data });
  }

  loginUser(data) {
    const { staySignedIn } = data;
    localStorage.setItem("naira-x-stay-signed-in", staySignedIn);

    this.websocketWorker.postMessage({ action: "login-user", data });
  }

  userAlreadyExists() {
    this.dispatchNotification(
      "warning",
      "This Email/Username is already in use",
      "Try using a different email/username"
    );
  }

  accountCreationError() {
    this.dispatchNotification(
      "danger",
      "Your account cannot be created at this time"
    );
  }

  loginAttempt(action) {
    ++this.loginAttempts;

    if (this.loginAttempts === 3) {
      this.dispatchNotification(
        "warning",
        "You have inputted a wrong Username or Password 3 times. Did you forget your password?"
      );
      return null;
    }

    if (this.loginAttempts >= 5) {
      this.dispatchNotification(
        "danger",
        `Your actions on the ${this.formsWorker.type} form will be ignored. Please wait ${this.formsWorker.timeDisabled} seconds or cancel this form.`
      );
      this.formsWorker.timeDisabled =
        this.formsWorker.timeDisabled < 6900
          ? this.formsWorker.timeDisabled * 2
          : 6900;
      return null;
    }

    if (action === "user-not-found") {
      this.dispatchNotification(
        "warning",
        "Username or Password is incorrect."
      );
      return null;
    }
    if (action === "invalid-login-password") {
      this.dispatchNotification(
        "warning",
        "Username or Password is incorrect."
      );
      return null;
    }
  }

  logoutUser() {
    const guestId = localStorage.getItem("naira-x-guest-id");
    localStorage.removeItem("naira-x-refresh-token");
    if (guestId) {
      store.dispatch(set_user_id(guestId));
    } else {
      this.websocketWorker.postMessage({
        action: "get-guest-id",
      });
    }
    store.dispatch(set_is_guest(true));
  }

  updateStoreCache(type, data) {
    if (type === "post") {
      let { category } = data;

      if (category === null) {
        category = {
          title: "",
          icon: "",
        };
        data.category = category;
      }

      store.dispatch(updatePostCache(data));
    }
  }

  async createNewContent(data, contentType) {
    if (contentType === "post") {
      this.currentDialog(false);
    }

    this.dispatchNotification(
      "success",
      "Content sent for review.",
      "You'll be notified shortly."
    );

    const { file } = data;

    if (file) {
      if (file[0]) {
        const type = file[0].type.split("/")[0];
        if (type.toLowerCase() === "video") {
          const duration = await this.checkVideoDuration(file[0]);
          if (duration < 1 || duration > 60) {
            return this.dispatchNotification(
              "danger",
              "Post Rejected",
              "Invalid Video length."
            );
          }
          data.mediaType = "video";
        } else {
          data.mediaType = "image";
        }
        data.file = file[0];
      }
    }

    const { user } = store.getState();
    const { userId, token } = user;
    data.username = userId;
    data.token = token;

    if (contentType === "post") {
      this.hasPostInReview = true;
    }
    data.contentType = contentType;

    this.uploadContent(data);
  }

  async uploadContent(data) {
    this.fetchWorker = new Worker(
      new URL("./workers/FetchWorker/fetchWorker.js", import.meta.url)
    );

    this.fetchWorker.postMessage({ action: "start" });

    this.fetchWorker.postMessage({ action: "create-new-content", data });

    this.fetchWorker.addEventListener("message", this.handleFetchWorkerMessage);
  }

  handleFailedModeration() {
    this.dispatchNotification(
      "danger",
      "Content Rejected!!!",
      "Your content failed to meet our minimum community standard."
    );
    this.hasPostInReview = false;
  }

  handleFailedFileUpload() {
    this.dispatchNotification(
      "danger",
      "Media Upload Error.",
      "Sorry. Your media cannot be uploaded at this time."
    );
    this.hasPostInReview = false;
  }

  handleCreatePostInternalError() {
    this.dispatchNotification(
      "danger",
      "Internal Error.",
      "Sorry. Your post creation request cannot be processed at this time."
    );
  }

  handleContentCreationSuccess(data) {
    let { contentType } = data;
    contentType = contentType === "post" ? "Post" : "Comment";
    this.dispatchNotification("success", `${contentType} added successfully`);
    store.dispatch(setShowConfetti(true));

    if (contentType === "Post") {
      this.hasPendingFormChanges = false;
      this.hasPostInReview = false;
      this.resetPostsCache();
    }
    if (contentType === "Comment") {
      const { content } = data;
      this.websocketWorker.postMessage({
        action: "broadcast-comment",
        data: content,
      });
      store.dispatch(updateComments(content));
    }
  }

  applyComment(data) {
    store.dispatch(updateComments(data));
  }

  resetPostsCache() {
    store.dispatch(
      updatePostCache({
        title: "",
        body: "",
        category: { title: "", icon: "" },
        file: [],
        "media-tag": "neutral",
      })
    );
  }

  resetConfettiState() {
    store.dispatch(setShowConfetti(false));
  }

  checkVideoDuration(file) {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    return new Promise((resolve) => {
      video.onloadedmetadata = function () {
        const durationInSeconds = video.duration;
        resolve(durationInSeconds);
      };
    });
  }

  getTimeElapsed(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeElapsedInSeconds = Math.floor((currentDate - inputDate) / 1000);

    const units = [
      { label: "y", limit: 31536000 }, // 1 year in seconds
      { label: "w", limit: 604800 }, // 1 week in seconds
      { label: "d", limit: 86400 }, // 1 day in seconds
      { label: "h", limit: 3600 }, // 1 hour in seconds
      { label: "m", limit: 60 }, // 1 minute in seconds
      { label: "s", limit: 1 }, // 1 second in seconds
    ];

    let timeString = "";

    for (const unit of units) {
      if (timeElapsedInSeconds >= unit.limit) {
        const numUnits = Math.floor(timeElapsedInSeconds / unit.limit);
        timeString = `${numUnits}${unit.label}`;
        break;
      }
    }
    if (!timeString) {
      return "Just now";
    }
    return `${timeString} ago`;
  }

  dispatchVote(isComment, _id, voteType, token, userId) {
    this.websocketWorker.postMessage({
      action: "handle-vote",
      data: { _id, isComment, voteType, token, userId },
    });
  }

  applyVote(data) {
    const { isComment, content } = data;
    store.dispatch(updateVotes({ updatedContent: content, isComment }));
  }

  retrieveAndSetUserId() {
    const { user } = store.getState();
    const { isGuest, userId } = user;
    if (isGuest) {
      this.userId = "guest";
    } else {
      this.userId = userId;
    }
  }

  registerImpression(contentId, contentType, category) {
    if (!this.userId) {
      this.retrieveAndSetUserId();
    }

    if (contentType === "post") {
      store.dispatch(newPostView({ postId: contentId, userId: this.userId }));
      this.registerUserInterest(category);
      this.websocketWorker.postMessage({
        action: "persist-view",
        data: { contentId, userId: this.userId, contentType: "post" },
      });
    } else {
      this.websocketWorker.postMessage({
        action: "persist-view",
        data: { contentId, userId: this.userId, contentType: "comment" },
      });
    }
  }

  registerUserInterest(category) {
    store.dispatch(setInterests(category));
  }

  applyView(data) {
    const { contentId, userId, contentType } = data;
    store.dispatch(newPostView({ postId: contentId, userId }));
  }

  hideHeader() {
    store.dispatch(setShowHeader(false));
  }

  showHeader() {
    store.dispatch(setShowHeader(true));
  }

  persistMediaVolume(volume) {
    localStorage.setItem("naira-x-media-volume", volume);
  }

  getMediaVolume() {
    const volume = localStorage.getItem("naira-x-media-volume");
    if (typeof volume === "undefined") {
      return null;
    }
    return volume;
  }

  shouldShowCardPreviews() {
    const showPreviews = localStorage.getItem("naira-x-show-previews");
    if (typeof showPreviews === "undefined") {
      return true;
    }
    return showPreviews;
  }

  sortPosts(metrics) {
    store.dispatch(emptyPostsInStore());
    this.postsWorker.startDate = undefined;
    this.postsWorker.endDate = undefined;
    const data = { sortBy: metrics };
    this.refillStore(data);
  }

  getUserProfile(username) {
    store.dispatch(cachePostsToTemp());
    store.dispatch(emptyPostsInStore());
    store.dispatch(appendCurrentlyViewing(username));

    store.dispatch(setIsProfileModeActive(true));
    this.websocketWorker.postMessage({
      action: "get-user-profile",
      data: username,
    });
    store.dispatch(setIsLoadingProfile(true));
  }

  handleProfileData(data) {
    const {
      posts,
      profilePicture,
      bio,
      lastActivity,
      followers,
      following,
      createdAt,
      totalPosts,
    } = data;
    store.dispatch(setIsLoadingProfile(false));

    if (posts) {
      this.postsWorker.postMessage({ action: "handle-posts", data: posts });
    }
    store.dispatch(
      appendProfile({
        profilePicture,
        bio,
        lastActivity,
        followers,
        following,
        createdAt,
        totalPosts,
      })
    );
  }

  handleProfileClose() {
    store.dispatch(popLastProfile());
    store.dispatch(popLastViewedProfile());

    const { posts, user } = store.getState();
    const { temp } = posts;
    const { currentlyViewing } = user;

    if (temp.length > 0) {
      store.dispatch(emptyPostsInStore());
      const posts = temp[temp.length - 1];
      store.dispatch(addPostsToStore(posts));
      store.dispatch(popLastCachedPosts());
    }

    store.dispatch(setIsProfileModeActive(false));

    if (currentlyViewing.length > 0) {
      setTimeout(() => {
        store.dispatch(setIsProfileModeActive(true));
      });
    }
  }

  newContentShare(username, contentId, share, isAComment) {
    const contentType = isAComment ? "comment" : "post";
    const data = { userId: username, contentId, share, contentType };
    this.websocketWorker.postMessage({ action: "new-share", data });
  }

  newFollowing(username, following, isFollowing) {
    const data = { userId: username, following, isFollowing };
    this.websocketWorker.postMessage({ action: "new-following", data });
    store.dispatch(updateFollowing({ following, isFollowing }));
  }

  handleSearch(searchInput) {
    if (!this.searchMode && searchInput.length >= 3) {
      store.dispatch(cachePostsToTemp());
      store.dispatch(emptyPostsInStore());

      if (!this.searchMode) {
        this.searchMode = true;
      }
    }

    if (this.searchMode && searchInput.length < 3) {
      this.searchMode = false;
      store.dispatch(emptyPostsInStore());
      const { posts } = store.getState();
      const { temp } = posts;
      if (temp.length > 0) {
        store.dispatch(emptyPostsInStore());
        const posts = temp[temp.length - 1];
        store.dispatch(addPostsToStore(posts));
        store.dispatch(popLastCachedPosts());
      }
      this.searchString = undefined;
    }

    if (this.searchMode) {
      this.searchString = searchInput;
      this.websocketWorker.postMessage({
        action: "search-posts",
        data: searchInput,
      });
    }
  }
}

export default Master;
