import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { Pane } from "evergreen-ui";
import categories from "../assets/categories";

import { master } from "..";
import Category from "./Category";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "0px",
  marginRight: "0px",
  padding: "10px",
  paddingLeft: "4px",
  paddingRight: "4px",
};

function Categories() {
  const fixedOptions = [{ title: "For you", icon: "" }];

  const [value, setValue] = useState(fixedOptions);

  const handleChange = (e, newValue) => {
    if (newValue.length < 1) {
      newValue = fixedOptions;
    }
    setValue([...newValue]);

    document.activeElement.blur();
    master.refreshPosts(newValue);
  };

  const handleClick = (e) => {
    if (e.type === "focus") {
      master.hideHeader();
    }
    if (e.type === "blur") {
      master.showHeader();
    }
  };

  const handleInputFocus = (event) => {};

  const renderTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        label={option.title}
        {...getTagProps({ index })}
        disabled={option.title === "For you"}
      />
    ));

  return (
    <Pane style={style}>
      <Autocomplete
        size="small"
        multiple
        onChange={handleChange}
        options={categories}
        renderTags={renderTags}
        getOptionLabel={(option) => option.title}
        style={{
          width: 500,
          background: "var(--md-sys-color-secondary)",
          borderRadius: "4px",
        }}
        renderInput={(params) => (
          <TextField
            color="success"
            size="small"
            autoComplete={"true"}
            {...params}
            placeholder="Categories..."
            InputProps={{
              ...params.InputProps,
              onFocus: handleInputFocus, // Prevent input element focus
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <div
            {...props}
            style={{
              backgroundColor: state.selected
                ? "var(--md-sys-color-secondary)"
                : "initial",
            }}
          >
            <Category title={option.title} />
          </div>
        )}
        componentsProps={{
          paper: {
            sx: {
              bgcolor: "var(--md-sys-color-surface)",
              color: "var(--md-sys-color-on-surface)",
            },
          },
        }}
        value={value}
        onFocus={handleClick}
        onBlur={handleClick}
      />
    </Pane>
  );
}

export default Categories;
