import React from "react";
import Logo from "./Logo";
import { Pane } from "evergreen-ui";
import SignedInAs from "./SignedInAs";
import DateComponent from "./Date";
import { useSelector } from "react-redux";

const style = {
  padding: "8px",
};

function Header() {
  const { showHeader } = useSelector((state) => state.application);

  return (
    showHeader ? (
      <Pane background="var(--md-sys-color-primary)" style={style} elevation={1}>
        <Logo />
        <SignedInAs />
        <DateComponent />
      </Pane>
    ) : null
  );
}

export default Header;
