import { combineReducers } from "redux";
import applicationReducer from "./store/application";
import userReducer from "./store/user";
import cacheReducer from "./store/cache";
import postsReducer from "./store/posts";

const rootReducer = combineReducers({
  application: applicationReducer,
  cache: cacheReducer,
  user: userReducer,
  posts: postsReducer,
});

export default rootReducer;
