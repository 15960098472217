import { Combobox, Label, Pane, Text, TextInputField } from "evergreen-ui";
import nigeriaStates from "../../assets/nigeriaStates";
import { useState } from "react";
import { master } from "../..";

function RegisterForm() {
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    state: "",
    password: "",
    "confirm-password": "",
  });

  const [showUsernameHint, setShowUserNameHint] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    if (master.formsWorker) {
      master.formsWorker.postMessage({
        action: "update-form-data",
        data: updatedFormData,
      });
    }
  };

  return (
    <Pane>
      <TextInputField
        label="Email"
        hint={
          <Text color={"var(--md-sys-color-text)"} opacity="0.4">
            We only need your email for verification 😊
          </Text>
        }
        name="email"
        onChange={handleChange}
      />

      <TextInputField
        label="Username"
        name="username"
        onChange={handleChange}
        hint={
          showUsernameHint ? (
            <Text color={"var(--md-sys-color-text)"} opacity="0.4">
              Be Unique 😎
            </Text>
          ) : null
        }
        onFocus={() => setShowUserNameHint(true)}
        onBlur={() => setShowUserNameHint(false)}
      />

      <TextInputField
        label="Password"
        type="password"
        name="password"
        onChange={handleChange}
      />

      <TextInputField
        label="Confirm Password"
        type="password"
        name="confirm-password"
        onChange={handleChange}
      />
    </Pane>
  );
}

export default RegisterForm;
