import React, { useState, useEffect } from "react";
import { Pane, Text } from "evergreen-ui";

function DateComponent() {
  const style = {
    color: "var(--md-sys-color-on-primary)",
  };
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(interval);
    };
  }, []);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const formattedDate = currentDate.toLocaleDateString(undefined, options);

  return (
    <Pane
      maxWidth={"20em"}
      marginLeft={"auto"}
      marginRight={"auto"}
      textAlign="center"
      whiteSpace="nowrap"
    >
      <Text color={"var(--md-sys-color-on-primary)"}>{formattedDate}</Text>
    </Pane>
  );
}

export default DateComponent;
