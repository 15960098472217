import { Pane, SearchInput } from "evergreen-ui";
import { useState } from "react";
import { master } from "..";

function Search() {
  const style = {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    width: "fit-content",
  };

  const handleChange = (e) => {
    const input = e.target.value;
    master.handleSearch(input);
  };

  return (
    <Pane style={style}>
      <SearchInput onChange={handleChange} />
    </Pane>
  );
}

export default Search;
