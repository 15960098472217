const { Typography, Divider } = require("@mui/material");
const { Pane, Text } = require("evergreen-ui");

const descriptions = {
  politics: "Stay updated on political news and discussions.",
  crime: "Get informed about criminal activities and law enforcement.",
  romance: "Discuss matters of the heart and romantic tales.",
  "jobs/vacancies": "Explore job opportunities and career openings.",
  career: "Plan and enhance your professional journey.",
  business: "Share insights and updates on the world of business.",
  investment: "Learn about investment strategies and opportunities.",
  nysc: "Connect with fellow National Youth Service Corps members.",
  education: "Discover educational resources and career advice.",
  "foreign affairs": "Explore international relations and foreign affairs.",
  autos: "Discuss everything related to automobiles and vehicles.",
  "car talk": "Engage in conversations about cars and automotive topics.",
  properties: "Find information about real estate and property matters.",
  health: "Stay healthy with tips and medical advice.",
  travel: "Share travel experiences and get travel recommendations.",
  family: "Discuss family matters and parenting.",
  culture: "Explore cultural diversity and traditions.",
  religion: "Connect with others in discussions about faith and spirituality.",
  food: "Delve into the world of food and culinary delights.",
  diaries: "Share personal stories, thoughts, and experiences.",
  pets: "Connect with pet lovers and discuss all things related to pets.",
  agriculture: "Explore agricultural practices and farming techniques.",
  jokes: "Laugh out loud with jokes and humorous content.",
  "tv/movies": "Discuss your favorite TV shows and movies.",
  "music/radio": "Share your passion for music and radio entertainment.",
  celebrities: "Stay updated on the world of celebrities and entertainment.",
  fashion: "Dive into the world of fashion and style.",
  events: "Find out about upcoming events and gatherings.",
  sports: "Get in on the action with sports discussions and updates.",
  gaming: "Level up with gaming news, tips, and discussions.",
  forum_games: "Engage in fun and interactive forum games.",
  literature: "Explore the world of literature, books, and reading.",
  programming: "Connect with fellow programmers and discuss coding.",
  webmasters: "Get web development insights and tips for webmasters.",
  computers: "Discuss all things related to computers and technology.",
  phones: "Stay updated on the latest phone technology and gadgets.",
  "art, graphics & video":
    "Explore the world of art, graphics, and video creation.",
  "technology market": "Buy and sell tech products in the technology market.",
};

function Category({ title }) {
  return (
    <Pane textAlign="center" width={"100%"} overflowY="hidden">
      <Pane padding={"8px"}>
        <Typography sx={{ fontWeight: "bold" }}>{title} </Typography>
        <Text>{descriptions[title.toLowerCase()]}</Text>
      </Pane>
    </Pane>
  );
}

export default Category;
