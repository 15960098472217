import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  post: {
    title: "",
    body: "",
    category: { title: "", icon: "" },
    file: [],
    "media-tag": "neutral",
    mediaUrl: "",
  },
};

const cache = createSlice({
  name: "cache",
  initialState,
  reducers: {
    updatePostCache: (state, action) => {
      state.post = action.payload;
    },
  },
});

export const { updatePostCache } = cache.actions;

export default cache.reducer;
