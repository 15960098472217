import React, { useState } from "react";
import { Link, Typography } from "@mui/material";
import { Pane, Paragraph, Text } from "evergreen-ui";

function TrimmedContent({ content }) {
  const maxContentLength = 200;
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const trimmedContent =
    content.length > maxContentLength && !showFullContent
      ? `${content.substring(0, maxContentLength)}...`
      : content;

  const renderSeeMoreLink =
    content.length > maxContentLength &&
    (showFullContent ? (
      <Typography
        onClick={toggleContent}
        cursor="pointer"
        sx={{
          textAlign: "right",
          fontSize: "8pt",
          color: "var(--md-sys-color-text)",
          opacity: "0.4",
        }}
      >
        See Less
      </Typography>
    ) : (
      <Typography
        onClick={toggleContent}
        cursor="pointer"
        sx={{
          textAlign: "right",
          fontSize: "8pt",
          color: "var(--md-sys-color-text)",
          opacity: "0.4",
        }}
      >
        See More
      </Typography>
    ));

  return (
    <Pane whiteSpace="pre-wrap" hyphens="auto" padding={"8px"} paddingTop="2px">
      <Paragraph color="var(--md-sys-color-text)" textAlign="justify">
        <Text color="var(--md-sys-color-text)" fontSize="11.5pt">
          {trimmedContent}
        </Text>
      </Paragraph>
      {renderSeeMoreLink}
    </Pane>
  );
}

export default TrimmedContent;
