import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
  isGuest: true,
  interests: [],
  userId: undefined,
  email: undefined,
  bio: undefined,
  profilePictureUrl: undefined,
  location: undefined,
  isVerified: undefined,
  guestIdDescription: undefined,
  token: undefined,
  isUserLoaded: false,
  notifications: [],
  createdAt: undefined,
  followers: [],
  following: [],
  profile: [],
  currentlyViewing: [],
};

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    set_user_id: (state, action) => {
      state.userId = action.payload;
    },

    set_is_guest: (state, action) => {
      state.isGuest = action.payload;
    },

    set_bio: (state, action) => {
      state.bio = action.payload;
    },

    set_token: (state, action) => {
      state.token = action.payload;
    },

    setGuestIdDescription: (state, action) => {
      state.guestIdDescription = action.payload;
    },

    setprofilePictureUrl: (state, action) => {
      state.profilePictureUrl = action.payload;
    },

    setLocation: (state, action) => {
      state.location = action.payload;
    },

    setInterests: (state, action) => {
      const { interests } = state;
      let categoryToAdd = action.payload;

      if (action.payload.length > 1 && Array.isArray(action.payload)) {
        state.interests = [...action.payload];
        return;
      }

      const existingIndex = interests.indexOf(categoryToAdd);

      if (existingIndex !== -1) {
        if (existingIndex > 0) {
          const temp = interests[existingIndex];
          interests[existingIndex] = interests[existingIndex - 1];
          interests[existingIndex - 1] = temp;
        }
      } else {
        interests.push(categoryToAdd);
      }

      state.interests = interests;
    },

    setVerificationStatus: (state, action) => {
      state.isVerified = action.payload;
    },

    setUserToken: (state, action) => {
      const { userId, token } = action.payload;
      state.userToken = token;
      localStorage.removeItem("naira-x-access-token");
      state.userId = userId;
      state.isGuest = false;
    },

    setIsLoaded: (state, action) => {
      state.isUserLoaded = action.payload;
    },

    setEmail: (state, action) => {
      state.email = action.payload;
    },

    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },

    removeOldestNotification: (state, action) => {
      if (state.notifications.length > 0) {
        let notifications = state.notifications;
        notifications.shift();
        state.notifications = notifications;
      }
    },

    setCreatedAt: (state, action) => {
      state.createdAt = action.payload;
    },

    setFollowers: (state, action) => {
      state.followers = action.payload;
    },

    setFollowing: (state, action) => {
      state.following = action.payload;
    },

    appendProfile: (state, action) => {
      state.profile.push(action.payload);
    },
    popLastProfile: (state, action) => {
      let profiles = state.profile;
      profiles.pop();
      state.profile = [profiles];
    },
    appendCurrentlyViewing: (state, action) => {
      state.currentlyViewing.push(action.payload);
    },

    popLastViewedProfile: (state, action) => {
      const viewedProfiles = state.currentlyViewing;
      viewedProfiles.pop();
      state.currentlyViewing = [...viewedProfiles];
    },

    updateFollowing: (state, action) => {
      const { following, isFollowing } = action.payload;
      let _following = state.following;
      if (isFollowing) {
        if (!_following.includes(following)) {
          _following.push(following);
        }
      } else {
        _following = _following.filter(
          (__following) => __following !== following
        );
      }
      state.following = _following;
    },
  },
});

export const {
  set_user_id,
  set_is_guest,
  setUserToken,
  setLocation,
  set_bio,
  setprofilePictureUrl,
  setInterests,
  setVerificationStatus,
  setGuestIdDescription,
  set_token,
  setIsLoaded,
  addNotification,
  removeOldestNotification,
  setEmail,
  setCreatedAt,
  setFollowers,
  setFollowing,
  appendProfile,
  popLastProfile,
  appendCurrentlyViewing,
  popLastViewedProfile,
  updateFollowing,
} = user.actions;

export default user.reducer;
