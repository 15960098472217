import {
  FileCard,
  FileUploader,
  Label,
  Li,
  Pane,
  RadioGroup,
  Text,
  TextInputField,
  Textarea,
  Ul,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { master } from "../..";
import { Autocomplete, TextField, Typography, styled } from "@mui/material";
import categories from "../../assets/categories";
import { useSelector } from "react-redux";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";

function CreateForm() {
  const draft = useSelector((state) => state.cache.post);
  const [showMediaTags, setShowMediaTags] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    body: "",
    category: "",
    file: [],
    "media-tag": "neutral",
    mediaUrl: "",
  });

  useEffect(() => {
    if (formData.mediaUrl === "") {
      setShowMediaTags(false);
    } else if (formData.mediaUrl !== "" && formData.mediaUrl.length > 5) {
      setShowMediaTags(true);
    }

    const {
      title,
      body,
      category,
      file,
      "media-tag": mediaTag,
      mediaUrl,
    } = draft;

    setFormData({
      title,
      body,
      category,
      file,
      "media-tag": mediaTag,
      mediaUrl,
    });

    if (file.length > 0) {
      setShowMediaTags(true);
    }
    if (typeof master.formsWorker !== "undefined") {
      if (master.hasPendingFormChanges) {
        setTimeout(() => {
          master.formsWorker.postMessage({
            action: "update-form-data",
            data: { ...draft },
          });
        }, 1000);
      }
    }
  }, [formData.mediaUrl]);

  const [options] = useState([
    { label: "Neutral", value: "neutral" },
    { label: "Graphic", value: "graphic" },
    { label: "Explicit", value: "explicit" },
    { label: "Self-harm", value: "self-harm" },
  ]);

  const handleChange = (e) => {
    let updatedFormData;

    if (e[0]) {
      updatedFormData = { ...formData, file: [e[0]] };
      setShowMediaTags(true);
    } else {
      const { name, value } = e.target;
      updatedFormData = { ...formData, [name]: value };
    }
    setFormData(updatedFormData);

    master.formsWorker.postMessage({
      action: "update-form-data",
      data: updatedFormData,
    });
    master.hasPendingFormChanges = true;

    master.updateStoreCache("post", updatedFormData);
  };

  const handleRemove = () => {
    const updatedFormData = { ...formData, file: [] };
    setFormData(updatedFormData);
    master.formsWorker.postMessage({
      action: "update-form-data",
      data: updatedFormData,
    });
    setShowMediaTags(false);
  };

  const StyledAutocompleteOption = styled("div")({
    ":not(:last-child)": {
      borderBottom: "1px solid var(--md-sys-color-on-primary)",
    },
  });

  return (
    <Pane>
      <TextInputField
        label="Post Title"
        name="title"
        onChange={handleChange}
        maxLength={83}
        value={formData ? formData.title : ""}
        style={{ fontWeight: 700 }}
      />

      <Label htmlFor="textarea-2" marginBottom={4} display="block">
        Post Body
      </Label>
      <Textarea
        name="body"
        grammarly={true}
        spellCheck={true}
        onChange={handleChange}
        rows={3}
        className="body-medium"
        style={{ lineHeight: "1.5" }}
        value={formData ? formData.body : ""}
      />

      <br></br>
      <br></br>

      <Label>Select Category</Label>
      <Autocomplete
        options={categories}
        onChange={(event, newValue) =>
          handleChange({ target: { name: "category", value: newValue } })
        }
        getOptionLabel={(option) => (option ? option.title : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            margin="normal"
            variant="outlined"
            color="success"
            size="small"
          />
        )}
        renderOption={(props, option, state) => (
          <StyledAutocompleteOption
            {...props}
            style={{
              backgroundColor: state.selected
                ? "var(--md-sys-color-primary)"
                : "initial",
            }}
          >
            {option.title}
          </StyledAutocompleteOption>
        )}
        componentsProps={{
          paper: {
            sx: {
              bgcolor: "var(--md-sys-color-surface)",
              color: "var(--md-sys-color-on-surface)",
              maxHeight: "216px",
            },
          },
        }}
        value={formData.category}
      />

      <br></br>
      <br></br>

      <FileUploader
        label="Add Media File"
        name="file"
        description={<FileUploadDescription />}
        maxSizeInBytes={50 * 1024 ** 2}
        maxFiles={1}
        onChange={handleChange}
        renderFile={(file) => {
          const { name, size, type } = file;
          return (
            <FileCard
              key={name}
              name={name}
              onRemove={handleRemove}
              sizeInBytes={size}
              type={type}
            />
          );
        }}
        values={formData.file}
        display={formData.mediaUrl ? "none" : "flex"}
      />

      <Pane display={formData.file.length > 0 ? "none" : "block"}>
        <TextInputField
          label={<MediaLinks />}
          name="mediaUrl"
          onChange={handleChange}
          placeholder="paste video link here..."
          disabled={formData.file.length > 0}
          value={formData ? formData.mediaUrl : ""}
        />
      </Pane>

      {showMediaTags ? <MediaTags /> : null}
    </Pane>
  );

  function FileUploadDescription() {
    return (
      <Pane>
        <Ul>
          <Li>1 file (image/video) per post allowed.</Li>
          <Li>
            Video files uploaded from your device should not exceed 1 minute.
          </Li>
          <Li>Tag media content to protect minors.</Li>
        </Ul>
      </Pane>
    );
  }

  function MediaLinks() {
    return (
      <Pane>
        Or add a video link from Youtube{" "}
        <YouTubeIcon sx={{ marginBottom: "-0.3em" }} />
      </Pane>
    );
  }

  function AddMediaLink() {
    return (
      <Pane display={formData.file.length > 0 ? "none" : "block"}>
        <TextInputField
          label={<MediaLinks />}
          name="mediaUrl"
          onChange={handleChange}
          placeholder="paste video link here..."
          disabled={formData.file.length > 0}
          value={formData.mediaUrl}
        />
      </Pane>
    );
  }

  function MediaTags() {
    return (
      <Pane>
        <RadioGroup
          label="Select content tag:"
          value={formData["media-tag"]}
          options={options}
          onChange={handleChange}
          name="media-tag"
        />
      </Pane>
    );
  }
}

export default CreateForm;
