import React from "react";
import { Typography } from "@mui/material";

function Logo() {
  const style = {
    textAlign: "center",
    color: "var(--md-sys-color-on-primary)",
    padding: "8px",
  };

  return (
    <div>
      <div style={style}>
        <Typography>
          <span className="headline-large">₦aira-X Forum</span>
        </Typography>
      </div>
    </div>
  );
}

export default Logo;
