import { Skeleton, Tooltip } from "@mui/material";
import { Pane, Text } from "evergreen-ui";
import { useSelector } from "react-redux";

function SignedInAs() {
  const cssStyling = {
    textAlign: "center",
    color: "var(--md-sys-color-on-primary)",
    marginBottom: "0px",
    marginTop: "-0.8em",
    padding: "8px",
    maxWidth: "100%",
  };

  const isGuest = useSelector((state) => state.user.isGuest);
  const isUserLoaded = useSelector((state) => state.user.isUserLoaded);
  const userId = useSelector((state) => state.user.userId);
  const guestIdDescription = useSelector(
    (state) => state.user.guestIdDescription
  );

  return (
    <Pane marginLeft={"auto"} marginRight={"auto"}>
      {isUserLoaded ? (
        <Pane style={cssStyling}>
          <Text size={"small"}>
            Welcome, You Are Signed In As {isGuest ? "Guest" : userId}
          </Text>
        </Pane>
      ) : (
        <Skeleton
          sx={{
            marginTop: "-12px",
            maxWidth: "400px",
            display: "block",
            marginRight: "auto",
            marginLeft: "auto",
            width: "20em",
          }}
          animation="wave"
        />
      )}
    </Pane>
  );
}

export default SignedInAs;
