import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Dialog, Pane, SettingsIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";
// import { Typography, Skeleton, Tooltip, Link, Zoom } from "@mui/material";
import { useSelector } from "react-redux";
import RegisterForm from "./Forms/Register";
import LoginForm from "./Forms/Login";
import { master } from "..";
import CreateForm from "./Forms/Create";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import LoginTwoToneIcon from "@mui/icons-material/LoginTwoTone";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import DrawTwoToneIcon from "@mui/icons-material/DrawTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import Profile from "./Profile";
import Settings from "./Settings";

function NavLinks() {
  const { isGuest, isUserLoaded, userId } = useSelector((state) => state.user);
  const isProfileModeActive = useSelector(
    (state) => state.application.isProfileModeActive
  );

  const [formType, setFormType] = useState("Register");
  const [showDialog, setShowDialog] = useState(false);
  const noFooter = ["Profile", "Settings"];

  const handleClick = (type, e) => {
    e.preventDefault();
    if (type === "Create") {
      if (isGuest) {
        return master.dispatchNotification(
          "warning",
          "Guests cannot create posts at this moment."
        );
      }
      if (master.hasPostInReview) {
        return master.dispatchNotification(
          "warning",
          "You already have a post in review."
        );
      }
    }
    if (type === "Profile") {
      return master.getUserProfile(userId);
    }
    setShowDialog(true);
    setFormType(type);
  };

  const handleDialogOnOpenComplete = () => {
    master.initFormsWorker(formType);
  };

  const handleDialogOnCloseComplete = () => {
    if (formType !== "Profile") {
      master.terminateFormsWorker();
    }
    if (formType === "Create" && !master.hasPostInReview) {
      master.dispatchNotification(
        "success",
        "Draft saved successfully.",
        "Resume creating your post with your changes intact."
      );
    }
    if (formType === "Profile") {
      master.handleProfileClose();
    }
    if (showDialog) {
      setShowDialog(false);
    }
  };

  const handleDialogConfirm = () => {
    master.watchDialog(setShowDialog);
    if (master.formsWorker.isDisabled) {
      return null;
    }
    master.formsWorker.postMessage({ action: "validate-form-data" });
    return null;
  };

  useEffect(() => {
    if (isProfileModeActive) {
      setFormType("Profile");
    }
  }, [isProfileModeActive]);
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation showLabels>
        {isUserLoaded
          ? [
              isGuest
                ? [
                    <BottomNavigationAction
                      key="register"
                      label="Register"
                      icon={<PersonAddTwoToneIcon />}
                      onClick={(e) => handleClick("Register", e)}
                    />,
                    <BottomNavigationAction
                      key="login"
                      label="Login"
                      icon={<LoginTwoToneIcon />}
                      onClick={(e) => handleClick("Login", e)}
                    />,
                  ]
                : [
                    <BottomNavigationAction
                      key="profile"
                      label="Profile"
                      icon={<ManageAccountsTwoToneIcon />}
                      onClick={(e) => handleClick("Profile", e)}
                    />,
                    // <BottomNavigationAction
                    //   key="dm"
                    //   label="Chat"
                    //   icon={<MailTwoToneIcon />}
                    //   // onClick={() => master.logoutUser()}
                    // />,
                  ],

              <BottomNavigationAction
                key="create"
                label="Create"
                icon={<DrawTwoToneIcon />}
                onClick={(e) => handleClick("Create", e)}
              />,

              <BottomNavigationAction
                key="settings"
                label="Settings"
                icon={<SettingsSharpIcon />}
                onClick={(e) => handleClick("Settings", e)}
              />,
              !isGuest && [
                <BottomNavigationAction
                  key="dm"
                  label="Logout"
                  icon={<LogoutTwoToneIcon />}
                  onClick={() => master.logoutUser()}
                />,
              ],
            ]
          : null}
      </BottomNavigation>

      <Dialog
        isShown={showDialog || isProfileModeActive}
        title={formType || "Profile"}
        shouldAutoFocus={true}
        onOpenComplete={handleDialogOnOpenComplete}
        onCloseComplete={handleDialogOnCloseComplete}
        onConfirm={handleDialogConfirm}
        preventBodyScrolling={true}
        hasFooter={!noFooter.includes(formType)}
        style={{ paddingBottom: "-90px", padding: "0px" }}
        contentContainerProps={{
          paddingLeft: formType === "Profile" ? "0px" : "32px",
          paddingRight: formType === "Profile" ? "0px" : "32px",
        }}
        containerProps={{
          marginRight: "0px",
          marginLeft: "0px",
          marginTop: "0vmin",
          maxHeight: `calc(100% )`,
          maxWidth: `calc(100% )`,
          height: "100%",
        }}
      >
        {formType === "Register" ? (
          <RegisterForm />
        ) : formType === "Login" ? (
          <LoginForm />
        ) : formType === "Profile" ? (
          <Profile />
        ) : formType === "Create" ? (
          <CreateForm />
        ) : formType === "Settings" ? (
          <Settings />
        ) : null}
      </Dialog>
    </Paper>
  );
}

export default NavLinks;
