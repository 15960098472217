import { Divider } from "@mui/material";
import { Avatar, Pane, Paragraph, Strong, Text } from "evergreen-ui";
import { useSelector } from "react-redux";

import Search from "./Search";
import Categories from "./Categories";
import ContentDisplay from "./ContentDisplay";

const bioMaxLength = 247;

function Profile() {
  const isProfileModeActive = useSelector(
    (state) => state.application.isProfileModeActive
  );

  const { userId, followers, following, profilePictureUrl, bio, totalPosts } =
    useSelector((state) => {
      if (isProfileModeActive) {
        const isLoading = state.application.isLoadingProfile;
        const users = state.user.currentlyViewing;

        const userId = users[users.length - 1];
        if (isLoading) {
          return { isLoading, userId };
        }

        const profiles = state.user.profile;

        return { ...profiles[profiles.length - 1], userId };
      }
    });

  const avatarSize = 161;

  const postCount = totalPosts || 0;
  const followersCount = followers ? followers.length : 0;
  const followingCount = following ? following.length : 0;

  const centerStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };

  const countStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <Pane>
      <Pane style={centerStyles} className="spin">
        <Avatar
          src={profilePictureUrl}
          size={avatarSize}
          name={userId[0] + " " + userId[1]}
          color="transparent"
          style={{
            filter:
              "brightness(1.05) saturate(1.05) drop-shadow(var(--md-sys-color-on-primary) 0.5px 0.5px 3px)",
            marginBottom: "0.5em",
          }}
          className="shape"
        />
        <Paragraph
          marginBottom="0.2em"
          fontSize="medium"
          color="var(--md-sys-color-text)"
          paddingLeft="32px"
          paddingRight="32px"
        >
          <Strong fontSize="large">{userId}</Strong>
          <br></br> {bio}
        </Paragraph>
      </Pane>
      <Divider />
      <Pane
        display="flex"
        justifyContent="space-between"
        marginTop="16px"
        marginBottom="16px"
        paddingLeft="32px"
        paddingRight="32px"
      >
        <Pane style={countStyles}>
          <Text textAlign="center" color="var(--md-sys-color-text)">
            <Strong color="var(--md-sys-color-text)">{postCount}</Strong> <br />{" "}
            {`Post${postCount > 1 ? "s" : ""}`}
          </Text>
        </Pane>
        <Pane style={countStyles}>
          <Text textAlign="center" color="var(--md-sys-color-text)">
            <Strong color="var(--md-sys-color-text)">{followersCount}</Strong>{" "}
            <br /> Followers
          </Text>
        </Pane>
        <Pane style={countStyles}>
          <Text textAlign="center" color="var(--md-sys-color-text)">
            <Strong color="var(--md-sys-color-text)">{followingCount}</Strong>{" "}
            <br /> Following
          </Text>
        </Pane>
      </Pane>

      <Divider />
      <ContentDisplay mode={"profile"} />
    </Pane>
  );
}

export default Profile;
